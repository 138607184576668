const jp = {
    menu:{
        "home":"トップページ",
        "aboutUs":"会社案内",
        "businessSectors":"サービス",
        "successCases":"成功事例",
        "contactUs":"お問い合わせ",
    },
    messages: {
        "upload": "アップロード",
        "compamy": "Duosi Technology(Dalian)",
        "corporation":"Co.,Ltd",
        "slogan":"専門性 · イノベーション · 責任 · 協力ウインウイン",
        "button":'もっと詳しく知る',
    },
    companyOverview:{
        "title":"会社案内",
        "introduction":"Duosi Technologyは豊富な経験と先進技術を持つITシステムソリューション会社です",
        "textOne":"2013年の設立以来、私たちは10年の発展の道を歩んできました。業界の最前線を基盤にし、広範な市場需要と結びつけながら、国内外の先進的な管理理念やビジネスモデルを積極的に研究、消化、吸収してきました。ソフトウェア品質監査、スマートヘルスケア、政府と企業のデジタル化、産業システムのデジタルトランスフォーメーションなどの領域で、深い技術的蓄積を持っています。",
        "textTwo":"弊社は技術革新を中核とした競争力を持ち、お客様に最適なITアプリケーションソリューションと高品質のシステムの高可用性ソリューションを提供することを目指しています。ITアプリケーションに関する問題を解決し、お客様のITアプリケーションの発展を促進し、大きな価値を創造することを重視しています。",
        "corporateVision":'企業ビジョン',
        "corporateVisionT":'社会、お客様、従業員から尊敬されるテクノロジーサービス企業になる',
        "corporateMission":'企業の使命',
        "corporateMissionT":'情報技術を通じてお客様に利益と価値をもたらす。',
        "corporateValues":'企業の価値観',
        "corporateValuesT":'専門性・イノベーション',
        "corporateValuesTS":'責任・協力ウインウイン',
    },
    homeBusinessSector:{
        "title":"ビジネスセグメント",
        "introduction":"弊社は、ソフトウェア品質監査、スマートヘルスケア、政府と企業のデジタル化などの領域で豊富な技術経験を有しています。",
        businessSectorMenuOne:{
            "title":"Androidベースのソフトウェア品質監査",
            "one":"AndroidのAPPプラットフォームに上場認定サービス",
            "two":"ウェアラブルデバイスのソフトウェアシステム品質管理",
            "three":"モバイルデバイスのソフトウェアシステム品質管理",
            "four":"IoTデバイスのソフトウェアシステム品質管理",
            "five":"車載システムソフトウェア品質管理",
            "six":"スマートホームデバイスのソフトウェアシステム品質管理",
        },
        businessSectorMenuTwo:{
            "title":"スマートヘルスケアITソリューション",
            "one":"医療情報ビッグデータプラットフォーム",
            "two":"外来診療医師ワークステーション",
            "three":"患者情報パノラマクエリシステム",
            "four":"医療技術データ統合プラットフォーム",
            "five":"医療機関ITシステム国産化アップグレード改造プラン",
            "six":"",
        },
        businessSectorMenuThree:{
            "title":"政企システムデジタル化",
            "one":"OAシステム",
            "two":"ローカルエリアネットワークを利用した遠隔会議システム",
            "three":"スマート党建システム",
            "four":"電子文書システム",
            "five":"企業技能総合トレーニング・試験管理プラットフォーム",
            "six":"タスク管理システム",
        },
        businessSectorMenuFour:{
            "title":"産業システムのデジタルトランスフォーメーション",
            "one":"電力設備の総合検査プラットフォーム",
            "two":"露天鉱山の生産量をインテリジェントに統計分析するプラットフォーム",
            "three":"鉱業車両のスマートディスパッチプラットフォーム",
            "four":"採鉱車の制御および警告プラットフォーム",
            "five":"冶金エネルギーEMS管理プラットフォーム",
            "six":"化学プロセス制御情報化システム",
        },
        businessSectorMenuFive:{
            "title":"ツールおよびサービス",
            "one":"デジタル倉庫およびビッグデータプラットフォーム",
            "two":"データ可視化・分析プラットフォーム",
            "three":"データ交換共有センター",
            "four":"ストリーミングオーディオビデオライブ配信サービス",
            "five":"ジュエリーのインテリジェント鑑定プラットフォーム",
            "six":"拡張現実（AR）および仮想現実（VR）トレーニングシステム",
            "seven":"ブロックチェーンプラットフォームデータミドルウェア",
        },
        businessSectorMenuSix:{
            "title":"ITシステムおよびソフトウェアのカスタム開発",
            "one":"アプリケーション",
            "two":"モバイルアプリケーション",
            "three":"WeChatミニプログラム",
            "four":"VR、AR",
            "five":"IT情報システム国産化アップグレード改造",
            "six":"ブロックチェーン、Web3.0、およびDappの開発",
        },
    },
    homeImplementationProcess:{
        "title":"実施プロセス",
        "introduction":"厳格なプロジェクト実施プロセス、各フェーズで対応する成果物を出します。",
        "matterTitle":"事項",
        "matterOne":"立項",
        "matterTwo":"要件分析",
        "matterThree":"UIデザイン",
        "matterFour":"開発",
        "matterFive":"テスト",
        "matterSix":"納品",
        "matterSeven":"顧客再訪",
        "matterEight":"プロジェクトのまとめ",
        "achievementTitle":"成果物",
        achievementOne:{
            "one":"プロジェクト立項書"
        },
        achievementTwo:{
            "one":"プロトタイプ",
            "two":"要件文書",
            "three":"テストケース",
        },
        achievementThree:{
            "one":"UIデザイン図"
        },
        achievementFour:{
            "one":"プロジェクト計画表",
            "two":"進捗週報",
            "three":"インターフェース文書",
        },
        achievementFive:{
            "one":"テスト報告",
            "two":"使用説明書",
        },
        achievementSix:{
            "one":"受け入れ確認書"
        },
        achievementSeven:{
            "one":"訪問調査表"
        },
        achievementEight:{
            "one":"プロジェクトまとめ表"
        },
    },
    homeSuccessCases:{
        "title":"成功事例",
        "introduction":"お客様に最適なITアプリケーションソリューションと高品質のシステムの高可用性ソリューションを提供することを目指しています。",
    },
    about:{
        "title":"会社案内",
        "introduction":"",
    },
    enterpriseSize:{
        "title":"企業規模",
        "introduction":"本社は中国の遼寧省大連市に位置し、この地域に完全なビジネスネットワークと技術サービスシステムを備えており、2019年に大阪に支社を設立して、2020年に瀋陽に支社を設立して事業範囲を拡大しました。",
        "dl":"大連本部",
        "dl_address":"大連高新園創業ビルB棟1004",
        "sy":"沈陽支社",
        "sy_address":"沈陽浑南区国際ソフトウェアパークE17B棟408",
        "text":"弊社は、人工知能、インテリジェント製造、産業用インターネット、WEB3に事業を拡大し、より多様なソリューションを顧客に提供することを大いに期待しています。",
        "jp":"大阪営業所",
        "jp_address":"大阪府大阪市西区立売堀５丁目１番１１－２０５号 ",
    },

    honoraryQualifications:{
        "title":"名誉資格",
        "introduction":"弊社は設立から10年以上の間、技術の継続的な研究と業務上の不屈の努力により、国家知的財産管理システム認証、国家品質管理システム認証、国家高新技術企業など、多くの企業の資格認定を獲得してきました。さらに、当社の製品は30を超えるソフトウェア著作権を取得しています。"
    },
    corporateStructure:{
        "title":"会社の構造",
        "introduction":"弊社は、80人以上の専門および技術者のチームがあり、顧客のニーズに迅速に対応し、顧客が市場競争の課題に対応できる包括的な技術サービスを提供できます。 私たちの成功は、顧客を中心に、技術のリード、チームワーク、信頼と信義の価値観を持っていることです。 私たちは常に技術革新と顧客価値の向上を追求し、高い専門性と献身的な態度を保ち続けています。",
        "general_office":"総務室",
        "treasurer":"財務責任者",
        "personnel_manager":"人事責任者",
        "marketing_department":"マーケティング部門",
        "sales_director":"営業部長",
        "regional_manager":"地域マネージャー",
        "production_department":"製品部門",
        "product_manager":"製品マネージャー",
        "3d_modeler":"3Dモデラー",
        "ui_designer":"UIデザイナー",
        "development_department":"研究開発部門",
        "architect":"アーキテクト",
        "java_engineer":"Javaエンジニア",
        "app_engineer":"アプリエンジニア",
        "c++_engineer":"C++エンジニア",
        "project_manager":"プロジェクトマネージャー",
        "front_end_engineer":"フロントエンドエンジニア",
        "unity_engineer":"Unityエンジニア",
        "test_engineer":"テストエンジニア",
        "implementation_department":"実施部門",
        "implementation_service_engineer":"実施サービスエンジニア",
    },
    business:{
        "title":"ビジネスセグメント",
        "introduction":"",
    },
    businessOne:{
        "title":"Androidに基づくソフトウェア品質検証",
        one:{
            "left":"プロのAndroidソフトウェア製品品質テストサービスプロバイダとして、私たちはすべてのAndroidシステムで動作するソフトウェア製品に対して国際的に認知されたユーザビリティ認証検査を行い、顧客に正確で合理的な改善提案を提供し、顧客の製品が主要な権威機関の認証を通過し、製品のプロモーションを最大限にサポートし、お客様が製品の技術的価値の実現を実現できるように支援します。 ",
            "right_one_title":"互換性テストスイート (CTS)",
            "right_one_text":"Compatibility Test Suite、つまり、通常Googleの互換性認証を言います。 認証を通過したデバイスは理論的にAndroidで開発されたす。",
            "right_two_title":"Android企業推奨（AER）",
            "right_two_text": "Android Enterprise Recommendedは、Googleの厳格な企業要件を満たすデバイスとサービスプロバイダーを選定しています。このマークが付いているモバイルデバイスやサービスは、企業内で安心して使用できます。",
            "right_three_title": "ARCore OEMデバイス認証",
            "right_three_text": "ARCoreは、Googleの拡張現実（AR）エクスペリエンス構築プラットフォームです。さまざまなAPIを使用することで、ARCoreを通じてスマートフォンは環境を認識し、世界を理解し、情報と対話することができます。一部のAPIはAndroidとiOSで利用でき、共有のAR体験を実現します。"
        },
        two: {
            "right_text": "ソフトウェア製品の品質検証では、科学的プロセスモデルにより、検証結果をより現実的かつ客観的にすることができます。同時に、優れた検証プロセスは、品質検証の効率を大幅に向上させ、製品の欠陥や問題をより迅速かつ正確に特定することができます。このため、品質検証業務においては、W品質検証プロセスモデルを重点的に設計および開発しました。このモデルは製品開発プロセスと同期して品質管理を厳格に行い、開発段階での品質の把握を強化し、開発作業の効率を向上させることができます。また、システム要件文書と仕様書に厳密に従い、要求段階で製品の客観的かつ公正な品質評価とリスク警告を行うことで、製品計画段階に潜む品質に影響を及ぼす欠陥を発見するのに役立ちます。"
        },
        three: {
            "text": "弊社はAndroidシステムをベースとしたソフトウェアデバイスを含むさまざまなプロジェクト引き受けることができます。これには、モバイル通信デバイスソフトウェア、スマートウェアラブルデバイスオペレーティングシステム、車載スマートシステム、IoTなどが含まれます。製品の使用シーン、ネットワーク環境、機能に基づいて包括的で客観的な機能品質検査を行い、実際の有効な検査報告書と解決策を提供します。同時に、製品の要件に応じて主要なAndroid認証機関のテストリリースを行い、製品に対して認証済みの品質検査報告書を提供し、お客様の製品をスムーズに宣伝できるように支援することができます。"
        },
        four: {
            "one_title": "モバイル通信デバイスソフトウェア",
            "one_text": "スマートフォンのオペレーティングシステム、アプリ、タブレットのオペレーティングシステム、Androidシステムのコンポーネントなど",
            "two_title": "スマートウェアラブルデバイスシステム",
            "two_text": "スマートウォッチのオペレーティングシステム、フィットネストラッカーのオペレーティングシステム、ウェアラブルデバイスのコンポーネントなど",
            "three_title": "車載スマートシステム",
            "three_text": "スマートカーユニットシステム、カーディスプレイオペレーティングシステムなど",
            "four_title": "IoTシステム",
            "four_text": "スマートテレビのオペレーティングシステム、スマートホームソフトウェアシステム、セルフサービス機システム、病院のセルフサービスデバイスシステム、エンジニアリングコントロールデバイスソフトウェアシステムなど"
        },
    },
    businessTwo: {
        "title": "スマートヘルスITのソリューション",
        one:{
            "left":"当社は国の産業政策や全民健康インフォメーション分野の関連規格に基づき、十数年の業界経験や蓄積、そして先進技術力をもとに、お客様中心の理念を貫き、お客様が直面する業務開発においてのプレッシャーやチャレンジに焦点を当て、クラウド、ビッグデータ、モバイル、セキュリティ、ミドルウェアなど、先進の情報技術とアーキテクチャの思考を結合し、病院に対してスマートヘルス情報化建設の総合的なソリューションを提供しています。これには、顧客関係管理、病院の中核業務、医療管理、学科構築、運営管理、オフィス自動化、医療グループ構築、インターネット病院、病院情報プラットフォーム、IT運用保守、コンサルティング、計画などが含まれます。これにより、お客様は継続的により良い患者の医療エクスペリエンスを提供し、より便利な操作エクスペリエンス、より先進的でスマートな管理モード、より強力な学科構築システム、より卓越した運営システム、より安全で信頼性の高い情報化運用システムを実現し、病院のIT戦略をサポートしています。",
        },
        two:{
            "right_title":"医療情報化ビッグデータプラットフォーム",
            "right_text_one":"当社の医療情報化ビッグデータプラットフォームソリューションは、",
            "right_text_two":"HL7などの医療業界のデータ技術の標準規格に基づき、自社開発の医療ESBデータサービスバスエンジンを中心に構築され、標準技術フレームワークおよび構造に準拠して、病院内の各医療情報化システムをSOA技術を使用して中央集権化しました。",
            "right_text_three":"これにより、既存の情報システムのビジネスロジックを変更せずに、すべてのビジネスプロセスを情報化データ統合およびプラットフォームを介した統一分配を実現します。それによって、",
            "right_text_four":"病院内の各情報化アプリケーションシステム間の情報連携が実現されます。",
            "right_text_five":"また、このプラットフォームは、国が定めた関連情報規格に準拠する必要があります。これにより、異なる科学技術と医療関連のデータ相互通信、情報共有、およびビジネス標準化のデータ交換を実現することができます。",
        },
        three:{
            "left_title":"外来診療医師ワークステーション",
            "left_text_one":"伝統的な情報化建設において存在する問題や欠点、情報表示の不正確性、不完全性、歴史情報の不完全性、開業手続きの煩雑さ、利便性の低さ、メンテナンスの困難さ、業務の進捗の閉ループ不可能などの痛点を解決し、増大する業務ニーズや管理ニーズに対応するために、業務関連のリンクネットの改善、ラージタイプグループの作成、電子カルテのテンプレート作成などのキー問題に注力し、病院の現実環境に基づいて国家智慧医院の基準に合致した外来診療医師ワークステーションシステムを構築する。",
            "left_text_two":"システムは外来診療医師の日常的な勤務を基本にして、医師の業務効率を重視する。現在のシステムに外付けされているソフトウェアの機能を統合し、ワンストップの操作を実現する。権限を整理し、精緻化されたサービスインターフェースを提供する。患者情報のリンクを前院、中院、後院の段階で接続する。柔軟でオープンなデータインターフェイスと監査と警告の構成を提供し、医療従事者が患者に関する情報を迅速かつ正確に検索できるようにする。半構造化電子カルテの書き方とテンプレート作成、検査申請と検査申請機能を統合し、システムはメンテナンスが易しく、サービスが重視されている軽いクライアントであることを特色とすることを目標としています。",
        },
        four:{
            "left_title":"患者情報全景検索システム",
            "left_text":"全景患者検索システムは、データセンターから取得したデータを統計分析し、精密複合検索方式を採用して、医療情報の多次元検索サービスを提供します。医療画像、患者病歴、診療データなどのデータ資料検索などを含む。同時に、医療一体化連合の政策導向に基づき、ビッグデータクエリシステムは、クラウド画像跨院検索、患者リモートコンサルテーション、患者情報共有などの機能をサポートします。",
            "right_title":"医療技術データ統合プラットフォーム",
            "right_text":"医療技術データ統合プラットフォームは、自社開発のWSBサービスバスエンジンを核心に構築され、SOAマイクロサービス技術を通じて、院内の他の情報システムとデータおよび業務の相互接続を実現します。同時に、取得したデータのクリーニング、同期、アーカイブなどをサポートします。",
        }
    },
    businessThree: {
        "title": "政府・企業システムのデジタル化",
        one: {
            "text": "大政務分野では、デジタル技術と政務サービスの深い革新融合の構築に力を入れ、全体の連動、部門の協同、一網通弁公室の「インターネット＋政務サービス」システムサービス「システムは持続的に整備、深化し、政務サービスの標準化、ネットワーク化、インテリジェント化レベルを著しく向上させる。大政務融合プラットフォームを構築し、情報公開、政務内検索、多規則合一などのサービスを実現し、新しい情勢の下で「インターネット＋政務サービス」、「インターネット＋監督管理サービス」を核心とし、発改、工信、人社、税務、公衆など多主体の大政務サービスが含まれます。",
        },
        two: {
            "title": "ローカルエリアネットワーク（LAN）ベースのリモート会議システム",
            "text_one": "ローカルエリアネットワーク（LAN）ビデオ会議は、LANベースのストリーミングシステムとして、インターネット時代に登場した新しい会議形式です。データとイメージの転送、録画、保存、情報のコミュニケーションなどの多くの機能を備えたビデオ会議形式は、従来の会議では比較にならない利便性をもたらします。",
            "text_two": "特に、会議の内容に内部の機密要件がある場合や、ネットワーク環境に特殊な制限がある企業、学校、機関などの部門は、業務効率と品質に対する要求がますます高まる中、LANベースのストリーミングシステムの構築は、政府企業機関のシステムの近代化オフィス建設の重要な構成要素となっています。",
        },
        three: {
            "title": "スマート党建サービスシステム",
            "text": "スマート党建サービスシステムは、党建業務の展開をサポートするための機能を提供します。具体的な機能には、党建業務プロセスの組織、タスク管理、ガイドラインの学習、総合クエリなどが含まれます。対応するモジュールの内容は、管理側でメンテナンスできます。政府および企業の党建業務に情報化のサポートを提供し、日常の党建業務の効率を向上させ、人員コストを削減します。",
        },
        four: {
            "title": "総合スキルトレーニングおよび試験プラットフォーム",
            "text": "政府および企業のトレーニングシステムは、政府および企業に対して計画的かつ体系的なトレーニングと試験任務を実施する一種のプロジェクトです。目的は、従業員の知識、スキル、作業方法、作業態度、および作業の価値観を改善および向上させ、従業員の最大の潜在能力を発揮し、個人および組織の業績を向上させ、組織および個人の両方の発展を促進することです。"
        }
    },
    businessFour:{
        "title": "産業システムのデジタルトランスフォーメーション",
        one: {
            "text": "「中国製造2025」の全体目標の下で、産業システムのデジタルトランスフォーメーションの傾向は避けられません。弊社は国家の産業インテリジェンス化の方針に基づき、自社の技術力を活かし、IoT、ビッグデータ、人工知能などの新技術を活用し、顧客の実際のニーズに合わせて、異なる情報システムソリューションを通じてリアルタイム管理、情報深度セルフパーセプション、スマート最適自己決定、精密制御自己実行などのビジネスニーズを実現し、産業システムのプロセスの精密制御と実施管理を実現し、顧客のコストと時間の問題を解決し、製造業のスマート化を推進しています。",
        },
        two: {
            "title": "電力設備総合巡回監視プラットフォーム",
            "text_one": "電力設備総合巡回監視プラットフォームは、ドローン、センサーなどの機器を組み合わせ、先進の画像分析技術とビッグデータ技術を活用し、送電線路や変電所の変形、錆び、接触不良、漏電などの安全上のリスクを検出し、データのインテリジェンス化分析、構造化統計、可視化表示を行う統合型インテリジェントプラットフォームシステムです。",
            "text_two": "同プラットフォームは巡回データだけでなく、巡回担当者と巡回主体の管理およびタスクの割り当ても特別に設計されており、巡回タスクの割り当てからデータ収集、統計、警告までのビジネスラインを結びつけることで、電力設備の巡回効率を最大限に向上させ、巡回の安全リスクを軽減しています。",
        },
        three: {
            "title": "デジタル生産運営管理システム",
            "text_one": "デジタル生産運営管理システムは、製造業界の生産プロセスの進捗管理の難しさ、効率の低さ、倉庫業務のミスなどの実際の問題を解決するための一括管理システムです。",
            "text_two": "生産プロセス、品質管理、倉庫管理などの中核ノードの情報化を実現することで、企業の生産管理効率を向上させ、倉庫管理プロセスを最適化します。また、ビッグデータ技術を活用して製品の全工程品質トレースを実現し、工業製造業の情報化トランスフォーメーションを支援します。",
        }
    },
    businessFive:{
        "title": "ツールとサービス",
        one: {
            "title": "データウェアハウスとビッグデータプラットフォーム",
            "text": "ビッグデータプラットフォームは、さまざまなオペレーティングシステムと互換性があり、インポートデータのスマートな識別とデータ処理をサポートするだけでなく、強力な可視化ビッグスクリーン編集機能を提供しています。さまざまなデータソースの形式、さまざまなタイプの可視化コンポーネント、柔軟なプロジェクトのデリバリーモード、最適化されたソフトウェア操作体験を含んでいます。このシステムは、さまざまな業界のデータ分析と意思決定支援の機能要件を統合し、企業のエンタープライズレポート、データの可視化分析、セルフサービスの探索分析、データマイニングモデリング、AIインテリジェント分析などのビッグデータ分析ニーズに応えます。",
        },
        two: {
            "title": "データ可視化分析BIプラットフォーム",
            "text": "ビッグデータプラットフォームは、さまざまなオペレーティングシステムと互換性があり、インポートデータのスマートな識別とデータ処理をサポートするだけでなく、強力な可視化ビッグスクリーン編集機能を提供しています。さまざまなデータソースの形式、さまざまなタイプの可視化コンポーネント、柔軟なプロジェクトのデリバリーモード、最適化されたソフトウェア操作体験を含んでいます。このシステムは、さまざまな業界のデータ分析と意思決定支援の機能要件を統合し、企業のエンタープライズレポート、データの可視化分析、セルフサービスの探索分析、データマイニングモデリング、AIインテリジェント分析などのビッグデータ分析ニーズに応えます。",
        },
        three: {
            "title": "カラージュエリーのスマート鑑定プラットフォーム",
            "text_one": "カラージュエリーのスマート鑑定プラットフォームは、宝石業界で、カラージュエリーの伝統的な手動鑑定方法の主観性の強さ、明確な基準の欠如などの欠点に対応して開発されたデジタルカラージュエリー鑑定システムです。",
            "text_two": "このシステムは、カラージュエリーの品質データを定量化し、カラージュエリーの鑑定の信頼性と客観性を向上させ、カラージュエリーの鑑定プロセスにおける人件費を削減します。",
        }
    },
    businessSix:{
        "title": "ITシステムとカスタムソフトウェア開発",
        one: {
            "title": "専門サービス、お客様のビジネス成長を支援する",
            "text": "完璧な「オンサイト+ニアショア+オフショア」のデリバリーサービスモデルを確立することで、機器側からサーバー側までのライフサイクルを通じてソフトウェア開発/保守サービスをお客様に提供します。",
        },
        two: {
            "one_t": "アプリケーションソフトウェア",
            "two_t": "アプリ",
            "three_t": "WeChatミニプログラム",
            "four_t": "VR、AR",
            "five_t": "情報システムの国産化",
            "six_t": "ブロックチェーンDapp",
        },
    },
    successCases:{
        "title": "成功事例",
        one: {
            "title": "某知名病院",
            "introduction": "某知名病院の実際のニーズに応じて、病院情報化建設プロジェクトに全面的に協力します。",
            "text_one": "病院データセンタープラットフォーム",
            "text_two": "病院の電子カルテ管理システム",
            "text_three": "病院外来医師ワークステーション",
            "text_four": "病院医療スタッフ評価管理プラットフォーム",
            "text_five": "病院ビデオ会議システム",
        },
        two: {
            "title": "某国際宝石研究所",
            "introduction": "この宝石研究所は1980年7月にロサンゼルスで設立されました。40年以上にわたり、この宝石研究所はカラージュエリーの研究、鑑定、普及に取り組んでおり、カラージュエリー市場の標準化システムの構築に豊富な経験と大きな成果を持っています。",
            "text_one": "カラージュエリー研究所の検査システム",
            "text_two": "ルースストーン取引システム",
        },
        three: {
            "title": "国家電力某市供電会社",
            "introduction": "ある市の電力会社は、国内特大規模の電力企業であり、都市の電力網計画、建設、運営、電力供給を担当しています。供給エリアは1.26万平方キロメートルで、電力利用客数は284万人、人口は613万人です。同社は16の機能部門、13の電力供給支社、14の専門作業区域、そして同市電力建設グループ株式会社などの多様な子会社を有しています。",
            "text_one": "スマート党建システム",
            "text_two": "党組織建設標準化ワンストッププロジェクト",
        }
    },
    contact:{
        "title": "お問い合わせ",
        "contacts": "担当者",
        "contacts_name": "楊氏",
        "telephone": "電話番号",
        "phone": "186-4261-3117",
        "email":"メールアドレス",
        "emailnum":"yangjian@duostec.com",
    },
    certificate_title:{
        "title_one":"高新技術企業証明書",
        "title_two":"パソコンソフト著作権登録証明書",
        "title_three":"品質管理システム認証証明書",
    },
    img:{
        "frameworkImg":"2",
    }
}
export default jp
